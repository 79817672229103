import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    sales:[],
    count: 0,
    page: 1,
    limit: 10,
    pages: 1,
    stateLoad: 'idle',
    stateUpdate: 'idle',
}

export const loadSales = createAsyncThunk('sale/loadSales', async ({page,limit})=>{
    const resp = await axios.get('https://api.admin.gsocks.net/order',{
        headers: {
          'authorization': `session ${localStorage.getItem('token')}`
        },
        params: {
          page,limit
        }
    })
    return resp.data
})

export const cancelSale = createAsyncThunk('sale/cancelSale', async (data)=>{
    const resp = await axios.post(`https://api.admin.gsocks.net/order/cancel/${data.id}`,{note:data.note ? data.note : ""},{
        headers: {
          'authorization': `session ${localStorage.getItem('token')}`
        }
    })
    return resp.data
})

export const updateSale = createAsyncThunk('sale/updateSale', async (data)=>{
  const resp = await axios.post(`https://api.admin.gsocks.net/order/update-note/${data.id}`,{note:data.note ? data.note : ""},{
    headers: {
      'authorization': `session ${localStorage.getItem('token')}`
    }
  })
  return resp.data
})

export const searchSales = createAsyncThunk('sale/searchSales', async ({page,limit,searchText})=>{
  const resp = await axios.get('https://api.admin.gsocks.net/order',{
      headers: {
        'authorization': `session ${localStorage.getItem('token')}`
      },
      params: {
        page,limit,
        'filter': searchText
      }
  })
  return resp.data
})

export const saleSlice = createSlice({
  name: 'sale',
  initialState,
  reducers: {
    setStateUpdate: (state,action) => {
        state.stateUpdate = action.payload
    },
    setStateLimit: (state,action) => {
      state.limit = Number(action.payload)
    },
    setStatePage: (state,action) => {
      state.page = Number(action.payload)
    }
  },
  extraReducers: (build) => {
    build.addCase(loadSales.fulfilled, (state, action) => {
      state.count = action.payload.count
      state.sales = action.payload.orders
      state.pages = Math.ceil(state.count / state.limit)
      state.stateLoad = 'success'
    })
    build.addCase(loadSales.rejected, (state, action) => {
      state.stateLoad = 'error'
    })
    build.addCase(searchSales.fulfilled, (state, action) => {
      state.count = action.payload.count
      state.sales = action.payload.orders
      state.stateLoad = 'success'
    })
    build.addCase(cancelSale.fulfilled, (state, action) => {
      state.stateUpdate = 'success'
    })
    build.addCase(updateSale.fulfilled, (state, action) => {
      state.stateUpdate = 'success'
    })
  }
})


export const {setStateUpdate, setStateLimit, setStatePage} = saleSlice.actions
export default saleSlice.reducer