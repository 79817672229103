import React, {useEffect, useState} from 'react'
import styles from './Sales.module.css'
import Split from '../../components/Split/Split'
import CreateIcon from '@mui/icons-material/Create';
import Modal from '../../components/Modal/Modal';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import BlueBtn from '../../components/BlueBtn/BlueBtn';
import OutLineBtn from '../../components/OutLineBtn/OutLineBtn';
import {Alert,Snackbar} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { cancelSale, loadSales, searchSales, setStateLimit, setStatePage, setStateUpdate, updateSale } from '../../store/slices/saleSlice';
import { useNavigate } from 'react-router-dom';
import { setAuth } from '../../store/slices/userSlice';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const Sales = () => {

    const [order ,setOrder] = useState({
        order: 'id',
        order_type: 'ASC'
    })

    const saleState = useSelector((store)=>store.saleSlice)
    
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [activeModalEdit, setActiveModalEdit] = useState(false);
    const [activeModalEditSave, setActiveModalEditSave] = useState(false); 
    const [activeModalEditSaveCancel, setActiveModalEditSaveCancel] = useState(false);

    const [alertEditSuccess, setAlertEditSuccess] = useState(false);
    const [alertCancelTextError, setAlertCancelTextError] = useState(false);

    const [stateSale, setStateSale] = useState("")
    const [note, setNote] = useState("")
    const [infoDelete, setInfoDelete] = useState("")

    const [seleteced, setSelected] = useState({
        status: "",
        note: "",
        id: -1,
        statuses: []
    })
    
    const [saveFirst,setSaveFirst] = useState(false)

    const [timerInput, setTimerInput] = useState(null);
    
    const [searchText, setSearchText] = useState('');
    const [page,setPage] = useState(1);
    const [limit,setLimit] = useState(10);

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(goSearch, 1500);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [searchText]); 

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changeLimit, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [limit]); 

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changePage, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [page]); 

    useEffect(()=>{
        if (saveFirst) {
            if (stateSale == "Canceled" && seleteced.statuses.length > 1) {
                setActiveModalEditSaveCancel(true)
            } else {
                setActiveModalEditSave(true)
            }
            setSaveFirst(false)
        }
    },[saveFirst])


    useEffect(()=>{
        if (saleState.stateUpdate === 'success') {
            dispatch(loadSales({page:saleState.page,limit:saleState.limit,order}))
            setAlertEditSuccess(true)
            dispatch(setStateUpdate('idle'))
        } 
    },[saleState.stateUpdate])


    useEffect(()=>{
        if (saleState.stateLoad == 'success') {
            setStateSale(seleteced.status)
            setNote(seleteced.note)
        }
    },[seleteced])

    useEffect(()=>{
        goSearch()
    },[saleState.page, saleState.limit])


    useEffect(()=>{
        if (saleState.stateLoad == 'error') {
            localStorage.removeItem('token');
            dispatch(setAuth({auth:false,stateAuth:'error'}))
            navigate('/login')
        }
    },[saleState.stateLoad])

    useEffect(()=>{
        goSearch()
    },[order])

    const goSearch = () => {
        if(searchText != "") {
            dispatch(searchSales({page:saleState.page,limit:saleState.limit,searchText,order}))
        } else {
            dispatch(loadSales({page:saleState.page,limit:saleState.limit,order}))
        }
    };

    const changeLimit = () => {
        if (!limit) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else if (limit <= 0) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else {
            dispatch(setStateLimit(limit))
        }
    }

    const changePage = () => {
        if (!page) {
            setPage(1)
            dispatch(setStatePage(1))
        } else if (page > saleState.pages) {
            setPage(saleState.pages)
            dispatch(setStatePage(saleState.pages))
        } else {
            dispatch(setStatePage(page))
        }
    }


    const saveCancel = () => {
        if (infoDelete != '') {
            setActiveModalEditSaveCancel(false)
            dispatch(cancelSale({id:seleteced.id,note:infoDelete}))
            setInfoDelete("")
            const defSale = saleState.sales.find(s => s.id === seleteced.id)
            if (seleteced.note != defSale.note) {
                setActiveModalEditSave(true)
            }
        } else {
            setAlertCancelTextError(true)
        }
    }

    const save = () => {
        const defSale = saleState.sales.find(s => s.id === seleteced.id)
        if (seleteced.note != defSale.note) {
            dispatch(updateSale({id:seleteced.id,note:seleteced.note}))
        } else {
            setAlertEditSuccess(true)
        }
        setActiveModalEditSave(false)
    }


    const clickSort = (type) => {
        if (order.order == type) {
            setOrder({...order,order_type: order.order_type == 'ASC' ? 'DESC' : "ASC"})
        } else {
            setOrder({order:type, order_type:'ASC'})
        }
    }

    if (saleState.stateLoad == 'success') {
        return (
            <>
                <div className={styles.users}>
                    <h1>Sales</h1>
                    <Split />
                    <div className={styles.tableSection}>
                        <div className={styles.tableControllers}>
                            <input
                                type="text"
                                value={searchText}
                                onChange={(e)=>setSearchText(e.target.value)}
                                placeholder="search"
                            />
                        </div>
                        <div className={styles.table}>
                            <div className={styles.table_row}>
                                <div> ID</div>
                                <div> Proxies type</div>
                                <div> Value</div>
                                <div>Count</div>
                                <div> Sum</div>
                                <div>Full name</div>
                                <div>Mail</div>
                                <div> Date</div>
                                <div> Status</div>
                                <div>Actions</div>
                            </div>
                            {
                                saleState.sales.map((sale,idx)=> (
                                    <div className={styles.table_row} key={idx}>
                                        <div>{sale.id}</div>
                                        <div>{sale.type.trim()}</div>
                                        <div>{sale.volume}</div>
                                        <div>{sale.count}</div>
                                        <div>${sale.cost}</div>
                                        <div>{sale.full_name}</div>
                                        <div>{sale.email}</div>
                                        <div>{sale.create}</div>
                                        <div>{sale.status}</div>
                                        <div onClick={()=> {setActiveModalEdit(true);setSelected({
                                            ...sale,
                                            statuses: (sale.status === 'Canceled' ? ["Canceled"] : [sale.status,"Canceled"])
                                        }); setNote("")}}><CreateIcon /></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={styles.table_pagination}>
                            <div className={styles.table_pagination__rows}>
                                <span>Rows per page</span>
                                <input type="number" value={limit} onChange={(e)=>setLimit(e.target.value)} />
                            </div>
                            <div className={styles.table_pagination__pages}>
                                <KeyboardArrowLeftIcon onClick={()=>setPage((p)=>p == 1 ? 1 : p-1)}/>
                                <input type="number" value={page} onChange={(e)=>setPage(e.target.value)} />
                                <span>of</span>
                                <span>{saleState.pages}</span>
                                <KeyboardArrowRightIcon onClick={()=>setPage((p)=>p == saleState.pages ? p : p+1)}/>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal active={activeModalEdit} off={() => setActiveModalEdit(false)}>
                    <div className={styles.modalEdit} onClick={(e)=>e.stopPropagation()}>
                        <h2>Action</h2>
                        <Split style={{marginTop:"20px", marginBottom:"20px"}}/>
                        <div className={styles.modalEdit__header}>
                            <div>
                                <span>ID: {seleteced.id}</span>
                                <span>Value: {seleteced.count}{seleteced.volume}</span>
                            </div>
                            <div>
                                <span>Proxies type: {seleteced.type}</span>
                                <span>Sum: ${seleteced.cost}</span>
                            </div>
                        </div>
                        <div>
                            <span>Status sale</span>
                            <select onChange={(e)=>{setStateSale(e.target.value); setSelected({...seleteced, status: e.target.value})}} value={seleteced.status}>
                                {seleteced.statuses.map((s)=>s != "Canceled" ? <option value={s}>{s}</option> : <option value={s}>{s}</option>)}
                            </select>
                        </div>
                        <div>
                            <span>Note</span>
                            <textarea onChange={e=>setSelected({...seleteced,note:e.target.value})} value={seleteced.note ? seleteced.note : ""}></textarea>
                        </div>
                        <Split style={{marginTop:"20px", marginBottom:"20px"}}/>
                        <div className={styles.modalEdit__btns}>
                            <OutLineBtn text={"Cancel"} onClick={()=>setActiveModalEdit(false)}/>
                            <BlueBtn text={"Save"} onClick={()=> {setActiveModalEdit(false);setSaveFirst(true)}}/>
                        </div>
                    </div>
                </Modal>
                <Modal active={activeModalEditSave} off={() => setActiveModalEditSave(false)}>
                    <div className={styles.modalEdit} onClick={(e)=>e.stopPropagation()}>
                        <h2 style={{marginBottom:"30px", marginTop:"20px"}}>Are you sure to save the changes ?</h2>
                        <div className={styles.modalEdit__btns}>
                            <OutLineBtn text={"Cancel"} onClick={()=>setActiveModalEditSave(false)}/>
                            <BlueBtn text={"Save"} onClick={save}/>
                        </div>
                    </div>
                </Modal>
                <Modal active={activeModalEditSaveCancel} off={() => setActiveModalEditSaveCancel(false)}>
                    <div className={styles.modalEdit} onClick={(e)=>e.stopPropagation()}>
                        <h2 style={{marginBottom:"30px", marginTop:"20px"}}>Enter the reason for cancellation</h2>
                        <div className={styles.modalEdit__enter}>
                            <span>Note</span>
                            <textarea value={infoDelete} onChange={(e)=>setInfoDelete(e.target.value)}></textarea>
                        </div>
                        <div className={styles.modalEdit__btns}>
                            <OutLineBtn text={"Cancel"} onClick={()=>{setActiveModalEditSaveCancel(false);setActiveModalEditSave(true)}}/>
                            <BlueBtn text={"Save"} onClick={saveCancel}/>
                        </div>
                    </div>
                </Modal>
                <Snackbar open={alertEditSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={()=>setAlertEditSuccess(false)}>
                  <Alert onClose={()=>setAlertEditSuccess(false)} severity="success">
                  Data updated successfully !
                  </Alert>
                </Snackbar>
                <Snackbar open={alertCancelTextError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={()=>setAlertCancelTextError(false)}>
                  <Alert onClose={()=>setAlertCancelTextError(false)} severity="error">
                  Enter the reason for cancellation !
                  </Alert>
                </Snackbar>
            </>
        )
    } else return ''
}

export default Sales