// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `



.Card_card__-SwHU {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 290px;
    height: 60px;
    
    border-radius: 8px;
    border: 2px solid #d9d9ff;

    padding: 40px !important;
    margin: 20px !important;
}

.Card_card__-SwHU > div {
    color: rgb(124, 124, 124);
}

.Card_card__-SwHU > div:nth-child(1) > div:nth-child(2) {
    font-size: 23px;
    font-weight: bold;
    margin-top: 5px;
    color: rgb(66, 66, 66);
}`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.module.css"],"names":[],"mappings":";;;;AAIA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;;IAEnB,YAAY;IACZ,YAAY;;IAEZ,kBAAkB;IAClB,yBAAyB;;IAEzB,wBAAwB;IACxB,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,sBAAsB;AAC1B","sourcesContent":["\r\n\r\n\r\n\r\n.card {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n\r\n    width: 290px;\r\n    height: 60px;\r\n    \r\n    border-radius: 8px;\r\n    border: 2px solid #d9d9ff;\r\n\r\n    padding: 40px !important;\r\n    margin: 20px !important;\r\n}\r\n\r\n.card > div {\r\n    color: rgb(124, 124, 124);\r\n}\r\n\r\n.card > div:nth-child(1) > div:nth-child(2) {\r\n    font-size: 23px;\r\n    font-weight: bold;\r\n    margin-top: 5px;\r\n    color: rgb(66, 66, 66);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Card_card__-SwHU`
};
export default ___CSS_LOADER_EXPORT___;
