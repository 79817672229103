// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.modal_modal__Nszx8 {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.226);

    top: 0px;
    left: 0px;

    display: none;
}

.modal_active__zwVdx {
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/modal.module.css"],"names":[],"mappings":";;AAEA;IACI,eAAe;IACf,WAAW;IACX,aAAa;IACb,sCAAsC;;IAEtC,QAAQ;IACR,SAAS;;IAET,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["\r\n\r\n.modal {\r\n    position: fixed;\r\n    width: 100%;\r\n    height: 100vh;\r\n    background-color: rgba(0, 0, 0, 0.226);\r\n\r\n    top: 0px;\r\n    left: 0px;\r\n\r\n    display: none;\r\n}\r\n\r\n.active {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `modal_modal__Nszx8`,
	"active": `modal_active__zwVdx`
};
export default ___CSS_LOADER_EXPORT___;
