import React, { useEffect, useState } from 'react'
import styles from './Login.module.css'
import BlueBtn from '../../components/BlueBtn/BlueBtn'
import { useDispatch, useSelector } from 'react-redux'
import { loginAction, setAuth } from '../../store/slices/userSlice'
import {Alert,Snackbar} from "@mui/material";
import { useNavigate } from 'react-router-dom'

const Login = () => {

  const dispatch = useDispatch()
  const user = useSelector((store) => store.userSlice)
  
  const [code,setCode] = useState('')
  const [errorAlert, setErrorAlert] = useState(false)

  const navigate = useNavigate()

  const login = () => {
    dispatch(loginAction(code))
  }

  useEffect(()=>{
    if (user.stateAuth == 'success') {
      navigate('/')
    } else if (user.stateAuth == 'error') { 
      setErrorAlert(true)
      dispatch(setAuth({auth:false,stateAuth:'idle'}))
    }
  },[user])

  return (
    <>
      <div className={styles.wrapper_login}>
          <div className={styles.login}>
              <h2>LOGIN</h2>
              <input type="text" value={code} onChange={(e)=>setCode(e.target.value)} placeholder='token'/>
              <BlueBtn text={"Authorization"} onClick={login}></BlueBtn>
          </div>
      </div>
      <Snackbar open={errorAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={()=>setErrorAlert(false)}>
          <Alert onClose={()=>setErrorAlert(false)} severity="error">
            Error authorization !
          </Alert>
      </Snackbar>
    </>
  )
}

export default Login