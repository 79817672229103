// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.create_create__1YWyT {
    margin-bottom: 150px;
}

.create_create__wrapper__a\\+Oum {
    padding: 0px 80px;
}

.create_create__block_standart__QNrHK,
.create_create__block__Kbaa1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 450px;
    margin-bottom: 20px;
}

.create_create__block_standart__QNrHK span {
    width: 20%;
}

.create_create__block_standart__QNrHK input,
.create_create__block_standart__QNrHK textarea {
    border: 2px solid #d9d9ff;
    padding: 10px;
    border-radius: 5px;
    width: 70%;
}

.create_create__block__Kbaa1 img {
    height: 100px;
}

.create_create__block__Kbaa1 div{
    width: 70%;
}

.create_create__block__Kbaa1 input,
.create_create__block__Kbaa1 select {
    border: 2px solid #d9d9ff;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 5px;
}

.create_create__block_standart__QNrHK textarea {
    min-height: 100px;
}

.create_create__buttons__tH9pY button{
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Blog/Update/create.module.css"],"names":[],"mappings":";;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;;IAEI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;;IAEI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;AACd;;AAEA;;IAEI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["\r\n\r\n.create {\r\n    margin-bottom: 150px;\r\n}\r\n\r\n.create__wrapper {\r\n    padding: 0px 80px;\r\n}\r\n\r\n.create__block_standart,\r\n.create__block {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    width: 450px;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.create__block_standart span {\r\n    width: 20%;\r\n}\r\n\r\n.create__block_standart input,\r\n.create__block_standart textarea {\r\n    border: 2px solid #d9d9ff;\r\n    padding: 10px;\r\n    border-radius: 5px;\r\n    width: 70%;\r\n}\r\n\r\n.create__block img {\r\n    height: 100px;\r\n}\r\n\r\n.create__block div{\r\n    width: 70%;\r\n}\r\n\r\n.create__block input,\r\n.create__block select {\r\n    border: 2px solid #d9d9ff;\r\n    padding: 10px;\r\n    border-radius: 5px;\r\n    width: 100%;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.create__block_standart textarea {\r\n    min-height: 100px;\r\n}\r\n\r\n.create__buttons button{\r\n    margin-right: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"create": `create_create__1YWyT`,
	"create__wrapper": `create_create__wrapper__a+Oum`,
	"create__block_standart": `create_create__block_standart__QNrHK`,
	"create__block": `create_create__block__Kbaa1`,
	"create__buttons": `create_create__buttons__tH9pY`
};
export default ___CSS_LOADER_EXPORT___;
