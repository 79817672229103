// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `




.CardList_cardlist__d0iXP {
    width: 80%;

    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

}`, "",{"version":3,"sources":["webpack://./src/components/CardsList/CardList.module.css"],"names":[],"mappings":";;;;;AAKA;IACI,UAAU;;IAEV,aAAa;IACb,uBAAuB;IACvB,eAAe;;AAEnB","sourcesContent":["\r\n\r\n\r\n\r\n\r\n.cardlist {\r\n    width: 80%;\r\n\r\n    display: flex;\r\n    align-items: flex-start;\r\n    flex-wrap: wrap;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardlist": `CardList_cardlist__d0iXP`
};
export default ___CSS_LOADER_EXPORT___;
