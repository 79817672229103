

import React from 'react'
import { Outlet } from 'react-router-dom'
import BarLeft from './BarLeft/BarLeft'
import styles from './Layout.module.css'

const Layout = () => {
  return (
    <>
        <header className={styles.header}></header>
        <main className={styles.main}>
            <BarLeft/>
            <div className={styles.outlet}>
              <Outlet/>
            </div>
        </main>
        <footer></footer>
    </>
  )
}

export default Layout