import React, {useEffect, useState} from 'react'
import Split from '../../components/Split/Split'
import styles from './statistic.module.css'
import BarChartComponent from '../../components/BarChartComponent/BarChartComponent'
import DateRange from '../../components/DateRange/DateRange'
import axios from 'axios'
import { setAuth } from '../../store/slices/userSlice';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
};

const weeksAgo = () => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
};

const Statistic = () => {
    const [date, setDate] = useState([weeksAgo(), new Date()])
    const [rangeDate, setRangeDate] = useState(1)
    const [data, setData] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(()=>{
        const initData = async () => {
            if (localStorage.getItem('token')) {
                try {     
                    const resp = await axios.get(
                        `https://api.admin.gsocks.net/analytic/registrations?start_date=${formatDate(date[0])}&end_date=${formatDate(date[1])}`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                'Authorization': `session ${localStorage.getItem('token')}`
                            }
                        }
                    )
                    const tmp = []
                    if (rangeDate == '1') {
                        for (const key in resp.data.day1) {
                            tmp.push({date:key,verifiedReg: resp.data.day1[key][0], unverifiedReg: resp.data.day1[key][1], totalReg:resp.data.day1[key][2]})
                        }
                    } else if (rangeDate == '7') { 
                        for (const key in resp.data.day7) {
                            tmp.push({date:key,verifiedReg: resp.data.day7[key][0], unverifiedReg: resp.data.day7[key][1], totalReg:resp.data.day7[key][2]})
                        }
                    } else { 
                        for (const key in resp.data.day30) {
                            tmp.push({date:key,verifiedReg: resp.data.day30[key][0], unverifiedReg: resp.data.day30[key][1], totalReg:resp.data.day30[key][2]})
                        }
                    }
                    console.log(tmp)
                    setData(tmp)
                } catch {
                    localStorage.removeItem('token');
                    dispatch(setAuth({auth:false,stateAuth:'error'}))
                    navigate('/login')
                }
            }
        }

        initData()
    },[date,rangeDate])


    return (
        <>
            <div className={styles.statistic}>
                <h1>Statistic</h1>
                <Split />
                <div className={styles.statistic__controllers}>
                    <DateRange 
                        dateRange={date}
                        setDateRange={(range)=>setDate(range)}
                    />
                    <select value={rangeDate} onChange={(e)=>setRangeDate(e.target.value)}>
                        <option value="1">1 days</option>
                        <option value="7">7 days</option>
                        <option value="30">30 days</option>
                    </select>
                </div>
                <BarChartComponent data={data} />
            </div>
        </>
    )
}

export default Statistic


