// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `




.Home_home__CMw1J {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

h1 {
    width: 100%;
    margin-top: 50px;
    padding-left: 80px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.module.css"],"names":[],"mappings":";;;;;AAKA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["\r\n\r\n\r\n\r\n\r\n.home {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    width: 100%;\r\n}\r\n\r\nh1 {\r\n    width: 100%;\r\n    margin-top: 50px;\r\n    padding-left: 80px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `Home_home__CMw1J`
};
export default ___CSS_LOADER_EXPORT___;
