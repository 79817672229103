import React, {useEffect, useState} from 'react'
import styles from './API.module.css'
import Split from '../../components/Split/Split'
import CreateIcon from '@mui/icons-material/Create';
import Modal from '../../components/Modal/Modal';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import BlueBtn from '../../components/BlueBtn/BlueBtn';
import OutLineBtn from '../../components/OutLineBtn/OutLineBtn';
import {Alert,Snackbar} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuth } from '../../store/slices/userSlice';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { loadApi, setStateLimit, setStatePage } from '../../store/slices/apiSlice';

const API = () => {

    const [order ,setOrder] = useState({
        order: 'id',
        order_type: 'ASC'
    })

    const ApiState = useSelector((store)=>store.apiSlice)
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    

    const [saveFirst,setSaveFirst] = useState(false)

    const [timerInput, setTimerInput] = useState(null);
    
    const [page,setPage] = useState(1);
    const [limit,setLimit] = useState(10);


    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changeLimit, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [limit]); 

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changePage, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [page]); 



    useEffect(()=>{
        goSearch()
    },[ApiState.page, ApiState.limit])


    useEffect(()=>{
        if (ApiState.stateLoad == 'error') {
            localStorage.removeItem('token');
            dispatch(setAuth({auth:false,stateAuth:'error'}))
            navigate('/login')
        }
    },[ApiState.stateLoad])

    useEffect(()=>{
        goSearch()
    },[order])

    const goSearch = () => {
        dispatch(loadApi({page:ApiState.page,limit:ApiState.limit}))
    };

    const changeLimit = () => {
        if (!limit) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else if (limit <= 0) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else {
            dispatch(setStateLimit(limit))
        }
    }

    const changePage = () => {
        if (!page) {
            setPage(1)
            dispatch(setStatePage(1))
        } else if (page > ApiState.pages) {
            setPage(ApiState.pages)
            dispatch(setStatePage(ApiState.pages))
        } else {
            dispatch(setStatePage(page))
        }
    }



    console.log(ApiState)

    if (ApiState.stateLoad == 'success') {
        return (
            <>
                <div className={styles.users}>
                    <h1>API</h1>
                    <Split />
                    <div className={styles.tableSection}>
                        <div className={styles.tableControllers}>
                        
                        </div>
                        <div className={styles.table}>
                            <div className={styles.table_row}>
                                <div>ID</div>
                                <div>Full name</div>
                                <div>Mail</div>
                                <div>Date</div>
                                <div>Private Proxies</div>
                                <div>Residential Proxies</div>
                                <div>Token</div>
                            </div>
                            {
                                ApiState.keys.map((k)=> (
                                    <div className={styles.table_row} key={k.id}>
                                        <div>{k.id}</div>
                                        <div>{k.full_name}</div>
                                        <div>{k.email}</div>
                                        <div>{k.create}</div>
                                        <div>{k['private_proxy']}</div>
                                        <div>{(k['residential_traffic']/1000).toFixed(2)}</div>
                                        <div>{k.token}</div>
                                    </div> 
                                ))
                            }
                        </div>
                        <div className={styles.table_pagination}>
                            <div className={styles.table_pagination__rows}>
                                <span>Rows per page</span>
                                <input type="number" value={limit} onChange={(e)=>setLimit(e.target.value)} />
                            </div>
                            <div className={styles.table_pagination__pages}>
                                <KeyboardArrowLeftIcon onClick={()=>setPage((p)=>p == 1 ? 1 : p-1)}/>
                                <input type="number" value={page} onChange={(e)=>setPage(e.target.value)} />
                                <span>of</span>
                                <span>{ApiState.pages}</span>
                                <KeyboardArrowRightIcon onClick={()=>setPage((p)=>p == ApiState.pages ? p : p+1)}/>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    } else return ''
}

export default API
                        