import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    links:[],
    count: 0,
    page: 1,
    limit: 10,
    pages: 1,
    stateLoad: 'idle',
    stateUpdate: 'idle',
    stateCreate: 'idle',
}


export const createAdvertising = createAsyncThunk('advertising/createAdvertising', async ({name,description})=>{
  const resp = await axios.post('https://api.admin.gsocks.net/advertising/link',{name,description, status: 'active'},{
      headers: {
        'authorization': `session ${localStorage.getItem('token')}`
      }
  })
  return resp.data
})

export const deleteAdvertising = createAsyncThunk('advertising/createAdvertising', async (id)=>{
  const resp = await axios.delete(`https://api.admin.gsocks.net/advertising/link/${id}`,{
      headers: {
        'authorization': `session ${localStorage.getItem('token')}`
      }
  })
  return resp.data
})

export const loadAdvertising = createAsyncThunk('advertising/loadAdvertising', async ({page,limit})=>{
    const resp = await axios.get('https://api.admin.gsocks.net/advertising/link',{
        headers: {
          'authorization': `session ${localStorage.getItem('token')}`
        },
        params: {
          page,limit
        }
    })
    return resp.data
})

export const cancelAdvertising = createAsyncThunk('advertising/cancelAdvertising', async (data)=>{
    const resp = await axios.post(`https://api.admin.gsocks.net/finance/cancel/${data.id}`,{note:data.note ? data.note : ""},{
        headers: {
          'authorization': `session ${localStorage.getItem('token')}`
        }
    })
    return resp.data
})

export const updateAdvertising = createAsyncThunk('advertising/updateAdvertising', async (data)=>{
  const resp = await axios.put(`https://api.admin.gsocks.net/advertising/link/${data.id}`,{description:data.description ? data.description : "", name:data.name, status:data.status},{
    headers: {
      'authorization': `session ${localStorage.getItem('token')}`
    }
  })
  return resp.data
})

export const searchAdvertising = createAsyncThunk('advertising/searchAdvertising', async ({page,limit,searchText})=>{
  const resp = await axios.get('https://api.admin.gsocks.net/finance',{
      headers: {
        'authorization': `session ${localStorage.getItem('token')}`
      },
      params: {
        page,limit,
        'filter': searchText
      }
  })
  return resp.data
})

export const advertisingSlice = createSlice({
  name: 'advertising',
  initialState,
  reducers: {
    setStateUpdate: (state,action) => {
        state.stateUpdate = action.payload
    },
    setStateCreate: (state,action) => {
      state.stateCreate = action.payload
    },
    setStateLimit: (state,action) => {
      state.limit = Number(action.payload)
    },
    setStatePage: (state,action) => {
      state.page = Number(action.payload)
    }
  },
  extraReducers: (build) => {
    build.addCase(createAdvertising.fulfilled, (state, action) => {
      state.stateCreate = 'success'
    })
    build.addCase(loadAdvertising.fulfilled, (state, action) => {
      state.count = action.payload.count
      if (state.count <= 0) state.count = 1
      state.links = action.payload.links
      state.pages = Math.ceil(state.count / state.limit)
      if (state.pages <= 0) state.pages = 1
      state.stateLoad = 'success'
    })
    build.addCase(loadAdvertising.rejected, (state, action) => {
      state.stateLoad = 'error'
    })
    build.addCase(searchAdvertising.fulfilled, (state, action) => {
      state.count = action.payload.count
      state.links = action.payload.links
      state.stateLoad = 'success'
    })
    build.addCase(cancelAdvertising.fulfilled, (state, action) => {
      state.stateUpdate = 'success'
    })
    build.addCase(updateAdvertising.fulfilled, (state, action) => {
      state.stateUpdate = 'success'
    })
  }
})


export const {setStateUpdate, setStateLimit, setStatePage, setStateCreate} = advertisingSlice.actions
export default advertisingSlice.reducer