


import React from 'react'
import styles from './Card.module.css'

const Card = ({title, info, icon}) => {
  return (
    <div className={styles.card}>
        <div>
            <div>{title}</div>
            <div>{info}</div>
        </div>
        <div>{icon}</div>
    </div>
  )
}

export default Card