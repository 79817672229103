import React from 'react'
import styles from './modal.module.css'

const Modal = ({children, active, off}) => {
  return (
    <div className={`${styles.modal} ${active ? styles.active : ''}`} onClick={off}>
        {children}
    </div>
  )
}

export default Modal