import React from 'react'
import CardsList from '../../components/CardsList/CardsList'
import styles from './Home.module.css'
import Split from '../../components/Split/Split'

const Home = () => {

  return (
    <div className={styles.home}>
      <h1>Dashboard</h1>
      <Split/>
      <CardsList />
    </div>
  )
}

export default Home