// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `



.blueBtn_button_blue__OPW6\\+ {
    background-color: #023e8a;
    color: white;

    border-radius: 8px;
    border: 2.5px solid #023e8a;

    font-size: 16px;
    font-weight: bold;

    transition: 1s;
    cursor: pointer;

    padding: 14px 18px;   
}

.blueBtn_button_blue__OPW6\\+:hover {
    opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/components/BlueBtn/blueBtn.module.css"],"names":[],"mappings":";;;;AAIA;IACI,yBAAyB;IACzB,YAAY;;IAEZ,kBAAkB;IAClB,2BAA2B;;IAE3B,eAAe;IACf,iBAAiB;;IAEjB,cAAc;IACd,eAAe;;IAEf,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["\r\n\r\n\r\n\r\n.button_blue {\r\n    background-color: #023e8a;\r\n    color: white;\r\n\r\n    border-radius: 8px;\r\n    border: 2.5px solid #023e8a;\r\n\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n\r\n    transition: 1s;\r\n    cursor: pointer;\r\n\r\n    padding: 14px 18px;   \r\n}\r\n\r\n.button_blue:hover {\r\n    opacity: 0.6;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_blue": `blueBtn_button_blue__OPW6+`
};
export default ___CSS_LOADER_EXPORT___;
