// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Login_wrapper_login__pdMHr {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Login_login__3cJrA {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
    border: 2px solid #d9d9ff;
}

.Login_login__3cJrA input {
    padding: 10px;
    border: 2px solid #d9d9ff;
    border-radius: 5px;
    margin-bottom: 15px;
    width: 300px;
}

.Login_login__3cJrA h2 {
    text-align: center;
    margin-bottom: 15px;
    color: #023e8a;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.module.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":["\r\n\r\n.wrapper_login {\r\n    height: 100vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.login {\r\n    display: flex;\r\n    flex-direction: column;\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n    border: 2px solid #d9d9ff;\r\n}\r\n\r\n.login input {\r\n    padding: 10px;\r\n    border: 2px solid #d9d9ff;\r\n    border-radius: 5px;\r\n    margin-bottom: 15px;\r\n    width: 300px;\r\n}\r\n\r\n.login h2 {\r\n    text-align: center;\r\n    margin-bottom: 15px;\r\n    color: #023e8a;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper_login": `Login_wrapper_login__pdMHr`,
	"login": `Login_login__3cJrA`
};
export default ___CSS_LOADER_EXPORT___;
