// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.split_split__SAMMe {
    border-bottom: 2px solid #d9d9ff;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Split/split.module.css"],"names":[],"mappings":";;AAEA;IACI,gCAAgC;IAChC,WAAW;AACf","sourcesContent":["\r\n\r\n.split {\r\n    border-bottom: 2px solid #d9d9ff;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"split": `split_split__SAMMe`
};
export default ___CSS_LOADER_EXPORT___;
