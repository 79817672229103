// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `




.outLineBtn_button_blue_outline__Q7MNt {
    background-color: white;
    color: #023e8a;

    border-radius: 8px;
    border-top: 1px solid #d9d9ff;
    border-right: 1px solid #d9d9ff;
    border-bottom: 2px solid #d9d9ff;
    border-left: 2px solid #d9d9ff;

    font-size: 16px;
    font-weight: bold;
    transition: 0.5s;
    cursor: pointer;

    padding: 14px 18px;   
}

.outLineBtn_button_blue_outline__Q7MNt:hover {
    background-color: #023e8a;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/OutLineBtn/outLineBtn.module.css"],"names":[],"mappings":";;;;;AAKA;IACI,uBAAuB;IACvB,cAAc;;IAEd,kBAAkB;IAClB,6BAA6B;IAC7B,+BAA+B;IAC/B,gCAAgC;IAChC,8BAA8B;;IAE9B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;;IAEf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":["\r\n\r\n\r\n\r\n\r\n.button_blue_outline {\r\n    background-color: white;\r\n    color: #023e8a;\r\n\r\n    border-radius: 8px;\r\n    border-top: 1px solid #d9d9ff;\r\n    border-right: 1px solid #d9d9ff;\r\n    border-bottom: 2px solid #d9d9ff;\r\n    border-left: 2px solid #d9d9ff;\r\n\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n    transition: 0.5s;\r\n    cursor: pointer;\r\n\r\n    padding: 14px 18px;   \r\n}\r\n\r\n.button_blue_outline:hover {\r\n    background-color: #023e8a;\r\n    color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_blue_outline": `outLineBtn_button_blue_outline__Q7MNt`
};
export default ___CSS_LOADER_EXPORT___;
