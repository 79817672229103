import React, {useEffect, useState} from 'react'
import styles from './replenishments.module.css'
import Split from '../../components/Split/Split'
import CreateIcon from '@mui/icons-material/Create';
import Modal from '../../components/Modal/Modal';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import BlueBtn from '../../components/BlueBtn/BlueBtn';
import OutLineBtn from '../../components/OutLineBtn/OutLineBtn';
import {Alert,Snackbar} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuth } from '../../store/slices/userSlice';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { cancelReplenishments, loadReplenishments, searchReplenishments, setStateLimit, setStatePage, setStateUpdate, updateReplenishments } from '../../store/slices/replenishmentsSlice';

const Replenishments = () => {

    const [order ,setOrder] = useState({
        order: 'id',
        order_type: 'ASC'
    })

    const replenishmentsState = useSelector((store)=>store.replenishmentsSlice)
    
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [activeModalEdit, setActiveModalEdit] = useState(false);
    const [activeModalEditSave, setActiveModalEditSave] = useState(false); 
    const [activeModalEditSaveCancel, setActiveModalEditSaveCancel] = useState(false);

    const [alertEditSuccess, setAlertEditSuccess] = useState(false);
    const [alertCancelTextError, setAlertCancelTextError] = useState(false);

    const [infoDelete, setInfoDelete] = useState("")

    const [seleteced, setSelected] = useState({
        status: "",
        note: "",
        id: -1,
        statuses: []
    })
    
    console.log(replenishmentsState)

    const [saveFirst,setSaveFirst] = useState(false)

    const [timerInput, setTimerInput] = useState(null);
    
    const [searchText, setSearchText] = useState('');
    const [page,setPage] = useState(1);
    const [limit,setLimit] = useState(10);

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(goSearch, 1500);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [searchText]); 

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changeLimit, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [limit]); 

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changePage, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [page]); 

    useEffect(()=>{
        if (saveFirst) {
            if (seleteced.status == "Canceled" && seleteced.statuses.length > 1) {
                setActiveModalEditSaveCancel(true)
            } else {
                setActiveModalEditSave(true)
            }

            setSaveFirst(false)
        }
    },[saveFirst])


    useEffect(()=>{
        if (replenishmentsState.stateUpdate === 'success') {
            dispatch(loadReplenishments({page:replenishmentsState.page,limit:replenishmentsState.limit, status:localStorage.getItem('status')}))
            setAlertEditSuccess(true)
            dispatch(setStateUpdate('idle'))
            
        } 
    },[replenishmentsState.stateUpdate])


    useEffect(()=>{
        goSearch()
    },[replenishmentsState.page, replenishmentsState.limit])


    useEffect(()=>{
        if (replenishmentsState.stateLoad == 'error') {
            localStorage.removeItem('token');
            dispatch(setAuth({auth:false,stateAuth:'error'}))
            navigate('/login')
        }
    },[replenishmentsState.stateLoad])

    useEffect(()=>{
        goSearch()
    },[order])

    const goSearch = () => {
        if(searchText != "") {
            dispatch(searchReplenishments({page:replenishmentsState.page,limit:replenishmentsState.limit,searchText,status:localStorage.getItem('filter')}))
        } else {
            dispatch(loadReplenishments({page:replenishmentsState.page,limit:replenishmentsState.limit,status:localStorage.getItem('filter')}))
        }
    };

    const changeLimit = () => {
        if (!limit) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else if (limit <= 0) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else {
            dispatch(setStateLimit(limit))
        }
    }

    const changePage = () => {
        if (!page) {
            setPage(1)
            dispatch(setStatePage(1))
        } else if (page > replenishmentsState.pages) {
            setPage(replenishmentsState.pages)
            dispatch(setStatePage(replenishmentsState.pages))
        } else {
            dispatch(setStatePage(page))
        }
    }

    console.log(replenishmentsState)

    const saveCancel = () => {
        if (infoDelete != '') {
            setActiveModalEditSaveCancel(false)
            dispatch(cancelReplenishments({id:seleteced.id,note:infoDelete}))
            setInfoDelete("")
            const defSale = replenishmentsState.replenishments.find(s => s.id === seleteced.id)
            if (seleteced.note != defSale.note) {
                setActiveModalEditSave(true)
            }
        } else {
            setAlertCancelTextError(true)
        }
    }

    const save = () => {
        const defSale = replenishmentsState.replenishments.find(s => s.id === seleteced.id)
        if (seleteced.note != defSale.note) {
            dispatch(updateReplenishments({id:seleteced.id,note:seleteced.note}))
        } else {
            setAlertEditSuccess(true)
        }
        setActiveModalEditSave(false)
    }

    const clickSort = (type) => {
        if (order.order == type) {
            setOrder({...order,order_type: order.order_type == 'ASC' ? 'DESC' : "ASC"})
        } else {
            setOrder({order:type, order_type:'ASC'})
        }
    }


    const [activeModalFilter, setActiveModalFilter] = useState(false)
    const [filter,setFilter] = useState("All")

    useEffect(()=>{
        const f = localStorage.getItem('filter')
        if (f) {
            setFilter(f)
        }
    },[])

    const saveFilter = () => {
        if (filter == "All") {
            localStorage.setItem('filter',filter)
        }
        localStorage.setItem('filter',filter)
        setActiveModalFilter(false)
        goSearch()
    }

    if (replenishmentsState.stateLoad == 'success') {
        return (
            <>
                <div className={styles.users}>
                    <h1>Replenishments</h1>
                    <Split />
                    <div className={styles.tableSection}>
                        <div className={styles.tableControllers}>
                            <BlueBtn text={"Filter"} onClick={()=>setActiveModalFilter(true)}/>
                            <input
                                type="text"
                                value={searchText}
                                onChange={(e)=>setSearchText(e.target.value)}
                                placeholder="search"
                            />
                        </div>
                        <div className={styles.table}>
                            <div className={styles.table_row}>
                                <div>ID</div>
                                <div>Payment</div>
                                <div>Sum</div>
                                <div>Full name</div>
                                <div>Mail</div>
                                <div>Date</div>
                                <div>Status</div>
                                <div>Actions</div>
                            </div>
                            {
                                replenishmentsState.replenishments.map((rep)=> (
                                    <div className={styles.table_row}>
                                        <div>{rep.id}</div>
                                        <div>{rep.payment_method}</div>
                                        <div>${rep.sum}</div>
                                        <div>{rep.full_name}</div>
                                        <div>{rep.email}</div>
                                        <div>{rep.create}</div>
                                        <div>{rep.status}</div>
                                        <div onClick={()=> {setActiveModalEdit(true);setSelected({
                                            ...rep,
                                            statuses: (rep.status === 'Canceled' ? ["Canceled"] : [rep.status,"Canceled"])
                                        });}}><CreateIcon /></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={styles.table_pagination}>
                            <div className={styles.table_pagination__rows}>
                                <span>Rows per page</span>
                                <input type="number" value={limit} onChange={(e)=>setLimit(e.target.value)} />
                            </div>
                            <div className={styles.table_pagination__pages}>
                                <KeyboardArrowLeftIcon onClick={()=>setPage((p)=>p == 1 ? 1 : p-1)}/>
                                <input type="number" value={page} onChange={(e)=>setPage(e.target.value)} />
                                <span>of</span>
                                <span>{replenishmentsState.pages}</span>
                                <KeyboardArrowRightIcon onClick={()=>setPage((p)=>p == replenishmentsState.pages ? p : p+1)}/>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal active={activeModalEdit} off={() => setActiveModalEdit(false)}>
                    <div className={styles.modalEdit} onClick={(e)=>e.stopPropagation()}>
                        <h2>Action</h2>
                        <Split style={{marginTop:"20px", marginBottom:"20px"}}/>
                        <div>
                            <span>Status</span>
                            <select onChange={(e)=>{setSelected({...seleteced, status: e.target.value})}} value={seleteced.status}>
                                {seleteced.statuses.map((s)=> <option value={s}>{s}</option>)}
                            </select>
                        </div>
                        <div>
                            <span>Note</span>
                            <textarea onChange={e=>setSelected({...seleteced,note:e.target.value})} value={seleteced.note ? seleteced.note : ""}></textarea>
                        </div>
                        <Split style={{marginTop:"20px", marginBottom:"20px"}}/>
                        <div className={styles.modalEdit__btns}>
                            <OutLineBtn text={"Cancel"} onClick={()=>setActiveModalEdit(false)}/>
                            <BlueBtn text={"Save"} onClick={()=> {setActiveModalEdit(false);setSaveFirst(true)}}/>
                        </div>
                    </div>
                </Modal>
                <Modal active={activeModalEditSave} off={() => setActiveModalEditSave(false)}>
                    <div className={styles.modalEdit} onClick={(e)=>e.stopPropagation()}>
                        <h2 style={{marginBottom:"30px", marginTop:"20px"}}>Are you sure to save the changes ?</h2>
                        <div className={styles.modalEdit__btns}>
                            <OutLineBtn text={"Cancel"} onClick={()=>setActiveModalEditSave(false)}/>
                            <BlueBtn text={"Save"} onClick={save}/>
                        </div>
                    </div>
                </Modal>
                <Modal active={activeModalEditSaveCancel} off={() => setActiveModalEditSaveCancel(false)}>
                    <div className={styles.modalEdit} onClick={(e)=>e.stopPropagation()}>
                        <h2 style={{marginBottom:"30px", marginTop:"20px"}}>Enter the reason for cancellation</h2>
                        <div className={styles.modalEdit__enter}>
                            <span>Note</span>
                            <textarea value={infoDelete} onChange={(e)=>setInfoDelete(e.target.value)}></textarea>
                        </div>
                        <div className={styles.modalEdit__btns}>
                            <OutLineBtn text={"Cancel"} onClick={()=>setActiveModalEditSaveCancel(false)}/>
                            <BlueBtn text={"Save"} onClick={saveCancel}/>
                        </div>
                    </div>
                </Modal>
                <Modal active={activeModalFilter} off={() => setActiveModalFilter(false)}>
                    <div className={styles.modalEdit} onClick={(e)=>e.stopPropagation()}>
                        <h2 style={{marginBottom:"30px", marginTop:"20px"}}>Enter the status for filter</h2>
                        <div className={styles.modalEdit__enter}>
                            <select onChange={(e)=>setFilter(e.target.value)} value={filter}>
                                <option value="All">All</option>
                                <option value="Completed">Completed</option>
                                <option value="Canceled">Canceled</option>
                                <option value="Created">Created</option>
                            </select>
                        </div>
                        <div className={styles.modalEdit__btns}>
                            <OutLineBtn text={"Cancel"} onClick={()=>setActiveModalFilter(false)}/>
                            <BlueBtn text={"Save"} onClick={saveFilter}/>
                        </div>
                    </div>
                </Modal>
                <Snackbar open={alertEditSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={()=>setAlertEditSuccess(false)}>
                  <Alert onClose={()=>setAlertEditSuccess(false)} severity="success">
                  Data updated successfully !
                  </Alert>
                </Snackbar>
                <Snackbar open={alertCancelTextError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={()=>setAlertCancelTextError(false)}>
                  <Alert onClose={()=>setAlertCancelTextError(false)} severity="error">
                  Enter the reason for cancellation !
                  </Alert>
                </Snackbar>
            </>
        )
    } else return ''
}

export default Replenishments


