import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    posts:[],
    count: 0,
    page: 1,
    limit: 10,
    pages: 1,
    stateLoad: 'idle',
}

export const loadPosts = createAsyncThunk('blog/loadPosts', async ({page,limit})=>{
    if (localStorage.getItem('token')) {
        const resp = await axios.get('https://api.admin.gsocks.net/blog',{
          headers: {
            'authorization': `session ${localStorage.getItem('token')}`
          },
          params: {
            page,limit
          }
      })
      return resp.data
    }
})

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setStateLimit: (state,action) => {
      state.limit = Number(action.payload)
    },
    setStatePage: (state,action) => {
      state.page = Number(action.payload)
    },
    updateStatus: (state,action) => {
      state.posts = state.posts.map((post)=> {
        if (post.id == action.payload.post.id) {
          post.status = action.payload.type
        }
        return post
      })
    },
    deletePost: (state,action) => {
      state.posts = state.posts.filter((post)=>post.id != action.payload)
      console.log(action.payload)
      console.log(state.posts)
    }
  },
  extraReducers: (build) => {
    build.addCase(loadPosts.fulfilled, (state, action) => {
        state.count = action.payload.count
        state.posts = action.payload.post
        state.pages = Math.ceil(state.count / state.limit)
        state.stateLoad = 'success'
    })
    build.addCase(loadPosts.rejected, (state) => {
      state.stateLoad = 'error'
    })
  }
})


export const {setStateLimit, setStatePage, updateStatus, deletePost} = blogSlice.actions
export default blogSlice.reducer