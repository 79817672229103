import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const ProxiesInfo = () => {

    const {id} = useParams()

    const [show,setShow] = useState(true)
    const [content,setContent] = useState('');
    
    useEffect(()=>{
        if (id) {
            axios.get(`https://api.admin.gsocks.net/proxy/residential-proxy-list/${id}`, {
                    headers: {
                        'authorization': `session ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                }).then(resp=>{
                    if (resp.status == 200) {
                        setShow(true)
                        setContent(resp.data)
                    } else {
                        window.open("https://gsocks.net/user/login")
                    }
                })
        }
    },[id])

    return (
        <>
            {
                show ?
                <div dangerouslySetInnerHTML={{ __html: content }} />
                :
                <h1>Not auth</h1>
            } 
        </>
    )
}

export default ProxiesInfo