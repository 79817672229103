// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.statistic_statistic__gNA-k {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.statistic_statistic__controllers__YYFVh {
    display: flex;
    justify-content: flex-start !important;
    margin-left: 80px;
    margin-bottom: 50px;
}

.statistic_statistic__controllers__YYFVh select{
    margin-left: 20px;
    padding: 10px;
    border: 1px solid #ced4da;
    background-color: white;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Statistic/statistic.module.css"],"names":[],"mappings":";;;AAGA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sCAAsC;IACtC,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,uBAAuB;IACvB,kBAAkB;AACtB","sourcesContent":["\n\n\n.statistic {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.statistic__controllers {\n    display: flex;\n    justify-content: flex-start !important;\n    margin-left: 80px;\n    margin-bottom: 50px;\n}\n\n.statistic__controllers select{\n    margin-left: 20px;\n    padding: 10px;\n    border: 1px solid #ced4da;\n    background-color: white;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statistic": `statistic_statistic__gNA-k`,
	"statistic__controllers": `statistic_statistic__controllers__YYFVh`
};
export default ___CSS_LOADER_EXPORT___;
