



import React, { useEffect, useState } from 'react'
import Card from '../Card/Card'
import styles from './CardList.module.css'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuth } from '../../store/slices/userSlice';

const CardsList = ({cards}) => {

  const [stats, setStats] = useState({})
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const load = async () => {
    try {
      const resp = await axios.get('https://api.admin.gsocks.net/analytic', {
          headers: {
            'authorization': `session ${localStorage.getItem('token')}`
          }
      })
      const data = resp.data
      setStats(data)
    } catch(err) {
      localStorage.removeItem('token');
      dispatch(setAuth({auth:false,stateAuth:'error'}))
      navigate('/login')
    }
  }

  useEffect(()=> {
    load()
  },[])


  return (
    <div className={styles.cardlist}>
      <Card key={1} title={"LIFETIME (LT)"} info={stats.lifetime} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"AVERAGE ORDER VALUE (AOV)"} info={`${stats.averageOrderValue} $`} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"REPEAT PURCHASE RATES (RPR))"} info={stats.repeatPurchaseRates} icon={<MonetizationOnIcon  />}/>
      <Card key={1} title={"ACTIVE USERS"} info={stats.active_user} icon={<GroupsIcon/>}/>
      <Card key={1} title={"USERS"} info={stats.users} icon={<GroupsIcon/>}/>
      <Card key={1} title={"TOTAL BALANCE OF USERS"} info={`${stats.balance} $`} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"TOTAl BALANCE OF AFFILIATE PROGRAM"} info={`${stats.affiliate_balance} $`} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"ACTIVE RESIDENTIAL PROXIES"} info={stats.residential} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"ACTIVE MOBILE PROXIES"} info={stats.mobile} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"ACTIVE SERVER PROXIES"} info={stats.datacenter} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"ACTIVE PRIVATE PROXIES"} info={stats.private} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"REFILLS TODAY"} info={`${stats.today} $`} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"REFILLS IN 7 DAYS"} info={`${stats.week} $`} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"REFILLS IN 30 DAYS"} info={`${stats.month} $`} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"REFILLS FOR THE WHOLE TIME"} info={`${stats.all} $`} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"TRAFFIC TODAY"} info={`${(Number(stats.residentialTrafficDay) / 1000).toFixed(2)} GB`} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"TRAFFIC IN 7 DAYS"} info={`${(Number(stats.residentialTrafficWeek) / 1000).toFixed(2)} GB`} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"TRAFFIC IN 30 DAYS"} info={`${(Number(stats.residentialTrafficMonth) / 1000).toFixed(2)} GB`} icon={<MonetizationOnIcon/>}/>
      <Card key={1} title={"TRAFFIC FOR THE WHOLE TIME"} info={`${(Number(stats.residentialTrafficAll) / 1000).toFixed(2)} GB`} icon={<MonetizationOnIcon/>}/>
    </div>
  )
}

export default CardsList