// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Layout_header__d1tn0 {
    height: 50px;
    border-bottom: 2px solid #d9d9ff;
    width: calc(100% - 200px);
    transform: translateX(200px);
}

.Layout_main__1RsEz {
    display: flex;
}

.Layout_outlet__t3K9u {
    margin-left: 200px;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout.module.css"],"names":[],"mappings":";;AAEA;IACI,YAAY;IACZ,gCAAgC;IAChC,yBAAyB;IACzB,4BAA4B;AAChC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf","sourcesContent":["\r\n\r\n.header {\r\n    height: 50px;\r\n    border-bottom: 2px solid #d9d9ff;\r\n    width: calc(100% - 200px);\r\n    transform: translateX(200px);\r\n}\r\n\r\n.main {\r\n    display: flex;\r\n}\r\n\r\n.outlet {\r\n    margin-left: 200px;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Layout_header__d1tn0`,
	"main": `Layout_main__1RsEz`,
	"outlet": `Layout_outlet__t3K9u`
};
export default ___CSS_LOADER_EXPORT___;
