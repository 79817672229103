import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    keys:[],
    count: 0,
    page: 1,
    limit: 10,
    pages: 1,
    stateLoad: 'idle',
    stateUpdate: 'idle',
}

export const loadApi = createAsyncThunk('api/loadAffiliate', async ({page,limit})=>{
  if (localStorage.getItem('token')) {
    const resp = await axios.get('https://api.admin.gsocks.net/client/api-key',{
        headers: {
          'authorization': `session ${localStorage.getItem('token')}`
        },
        params: {
          page,limit
        }
    })
    return resp.data
  }
})



export const ApiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setStateLimit: (state,action) => {
      state.limit = Number(action.payload)
    },
    setStatePage: (state,action) => {
      state.page = Number(action.payload)
    }
  },
  extraReducers: (build) => {
    build.addCase(loadApi.fulfilled, (state, action) => {
      state.count = action.payload.count
      state.keys = action.payload.keys
      state.pages = Math.ceil(state.count / state.limit)
      state.stateLoad = 'success'
    })
    build.addCase(loadApi.rejected, (state, action) => {
      state.stateLoad = 'error'
    })
  }
})


export const {setStateLimit, setStatePage} = ApiSlice.actions
export default ApiSlice.reducer