import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    affiliates:[],
    count: 0,
    page: 1,
    limit: 10,
    pages: 1,
    stateLoad: 'idle',
    stateUpdate: 'idle',
}

export const loadAffiliate = createAsyncThunk('affiliate/loadAffiliate', async ({page,limit})=>{
  if (localStorage.getItem('token')) {
    const resp = await axios.get('https://api.admin.gsocks.net/affiliate/link',{
        headers: {
          'authorization': `session ${localStorage.getItem('token')}`
        },
        params: {
          page,limit
        }
    })
    return resp.data
  }
})

export const deleteAffiliate = createAsyncThunk('affiliate/deleteAdvertising', async (id)=>{
    const resp = await axios.delete(`https://api.admin.gsocks.net/affiliate/link/${id}`,{
        headers: {
          'authorization': `session ${localStorage.getItem('token')}`
        }
    })
    return resp.data
  })

export const cancelAffiliate = createAsyncThunk('affiliate/cancelAffiliate', async (data)=>{
    const resp = await axios.post(`https://api.admin.gsocks.net/finance/cancel/${data.id}`,{note:data.note ? data.note : ""},{
        headers: {
          'authorization': `session ${localStorage.getItem('token')}`
        }
    })
    return resp.data
})

export const updateAffiliate = createAsyncThunk('affiliate/updateAffiliate', async (data)=>{
  const resp = await axios.put(`https://api.admin.gsocks.net/affiliate/link/${data.id}`,{name:data.name,status:data.status.toLowerCase(),description:data.description,note:data.note,balance:data.balance},{
    headers: {
      'authorization': `session ${localStorage.getItem('token')}`
    }
  })
  return resp.data
})

export const searchAffiliate = createAsyncThunk('affiliate/searchAffiliate', async ({page,limit,searchText})=>{
  const resp = await axios.get('https://api.admin.gsocks.net/affiliate/link',{
      headers: {
        'authorization': `session ${localStorage.getItem('token')}`
      },
      params: {
        page,limit,
        'filter': searchText
      }
  })
  return resp.data
})

export const affiliateSlice = createSlice({
  name: 'affiliate',
  initialState,
  reducers: {
    setStateUpdate: (state,action) => {
        state.stateUpdate = action.payload
    },
    setStateLimit: (state,action) => {
      state.limit = Number(action.payload)
    },
    setStatePage: (state,action) => {
      state.page = Number(action.payload)
    }
  },
  extraReducers: (build) => {
    build.addCase(loadAffiliate.fulfilled, (state, action) => {
      state.count = action.payload.count
      state.affiliates = action.payload.links
      state.pages = Math.ceil(state.count / state.limit)
      state.stateLoad = 'success'
    })
    build.addCase(loadAffiliate.rejected, (state, action) => {
      state.stateLoad = 'error'
    })
    build.addCase(searchAffiliate.fulfilled, (state, action) => {
      state.count = action.payload.count
      state.affiliates = action.payload.links
      state.stateLoad = 'success'
    })
    build.addCase(cancelAffiliate.fulfilled, (state, action) => {
      state.stateUpdate = 'success'
    })
    build.addCase(updateAffiliate.fulfilled, (state, action) => {
      state.stateUpdate = 'success'
    })
    build.addCase(updateAffiliate.rejected, (state, action) => {
      state.stateUpdate = 'success'
    })
  }
})


export const {setStateUpdate, setStateLimit, setStatePage} = affiliateSlice.actions
export default affiliateSlice.reducer