import React, {useEffect, useState, useRef} from 'react'
import styles from './create.module.css'
import Split from '../../../components/Split/Split'
import TinyMCEEditor from '../../../components/TinyMCEEditor/TinyMCEEditor';
import OutLineBtn from '../../../components/OutLineBtn/OutLineBtn';
import BlueBtn from '../../../components/BlueBtn/BlueBtn';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {Alert,Snackbar} from "@mui/material";
import axios from 'axios';

const BlogCreate = () => {

    const navigate = useNavigate();

    const [post, setPost] = useState({
        title: "",
        description: "",
        tags: [],
        author: "",
        link: "",
        text: "",
        seo_title: '',
        seo_description: '',
        duration: '',
        status: "In progress",
        photo: null,
        author_photo: null,
    })

    const postRef = useRef(post);
    const [postId, setPostId] = useState(-1)
    const [tags, setTags] = useState('');
    

    const [stateCreate, setStateCreate] = useState({success:false,error:false})

    useEffect(() => {
        const id = setInterval(async () => {
            if (postId == -1) {
                if (postRef.duration != '' && postRef.seo_title != '' && postRef.seo_description != '' && postRef.current.text != "" && postRef.current.title != "" && postRef.current.link != "" && postRef.current.description != "" && postRef.current.photo != null && postRef.current.author != "" && postRef.current.author_photo != null) {
                    createPost()
                } 
            } else {
                if (postRef.duration != '' && postRef.seo_title != '' && postRef.seo_description != '' && postRef.current.text != "" && postRef.current.title != "" && postRef.current.link != "" && postRef.current.description != "" && postRef.current.photo != null && postRef.current.author != "" && postRef.current.author_photo != null) {
                    updatePost()
                }
            }
        }, 30000);

        return () => {
            clearInterval(id);
        };
    }, [postId]);

    useEffect(()=>{
        setPost({...post,tags:tags.split(',')})
    },[tags])

    useEffect(() => {
        postRef.current = post;
    }, [post]);

    const handleSelectChange = (event) => {
        const selectedTag = event.target.value;

        if (selectedTag !== 'select') {
            if (!tags.includes(selectedTag)) {
                setTags(prevTags => prevTags ? `${prevTags},${selectedTag}` : selectedTag);
            }
            event.target.value = 'select';
        }
    };

    const handleImageChangeAuthor = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPost({...post,author_photo:reader.result.split(',')[1]}); 
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageChangeBlog = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPost({...post,photo:reader.result.split(',')[1]}); 
            };
            reader.readAsDataURL(file); 
        }
    };

    const createPost = async ()=>{
        try {
            if (localStorage.getItem('token')) {
                const resp = await axios.post('https://api.admin.gsocks.net/blog', postRef.current, {
                    headers: {
                        'authorization': `session ${localStorage.getItem('token')}`,
                    }
                })
                setPostId(resp.data.id)
                setStateCreate({success:true,error:false})
            }
        } catch (e) {
            setStateCreate({success:false,error:true})
        }
      }
      
    const updatePost = async ()=>{
        try {
            if (localStorage.getItem('token')) {
                await axios.patch(`https://api.admin.gsocks.net/blog/${postId}`, postRef.current, {
                    headers: {
                        'authorization': `session ${localStorage.getItem('token')}`,
                    }
                })
                setStateCreate({success:true,error:false})
            }
        } catch (e) {
            setStateCreate({success:true,error:false})
        }
    }


    const saveAction = () => {
        if (postId == -1) {
            if (postRef.current.text != "" && postRef.current.title != "" && postRef.current.link != "" && postRef.current.description != "" && postRef.current.photo != null && postRef.current.author != "" && postRef.current.author_photo != null) {
                createPost()
            } else {
                setStateCreate({success:false,error:true})
            }
        } else {
            if (postRef.current.text != "" && postRef.current.title != "" && postRef.current.link != "" && postRef.current.description != "" && postRef.current.photo != null && postRef.current.author != "" && postRef.current.author_photo != null) {
                updatePost()
            } else {
                setStateCreate({success:false,error:true})
            }
        }
    }

    return (
        <>
            <div className={styles.create}>
                <h1>Blog create</h1>
                <Split />
                <div className={styles.create__wrapper}>
                    <div className={styles.create__block_standart}>
                        <span>Title</span>
                        <input value={post.title} onChange={(e)=> setPost({...post,title:e.target.value})}/>
                    </div>
                    <div className={styles.create__block_standart}>
                        <span>SEO Title</span>
                        <input value={post.seo_title} onChange={(e)=> setPost({...post,seo_title:e.target.value})}/>
                    </div>
                    <div className={styles.create__block_standart}>
                        <span>SEO Description</span>
                        <input value={post.seo_description} onChange={(e)=> setPost({...post,seo_description:e.target.value})}/>
                    </div>
                    <div className={styles.create__block_standart}>
                        <span>Link</span>
                        <input value={post.link} onChange={(e)=> setPost({...post,link:e.target.value})}/>
                    </div>
                    <div className={styles.create__block_standart}>
                        <span>Time Read</span>
                        <input value={post.duration} onChange={(e)=> setPost({...post,duration:e.target.value})}/>
                    </div>
                    <div className={styles.create__block_standart}>
                        <span>Description</span>
                        <textarea value={post.description} onChange={(e)=> setPost({...post,description:e.target.value})}></textarea>
                    </div>
                    <div className={styles.create__block}>
                        <span>Photo</span> 
                        <div>
                            <input type="file" accept="image/*" onChange={handleImageChangeBlog} />
                        </div>
                    </div>
                    <div className={styles.create__block}>
                        <span>Tags</span>
                        <div>
                            <input type="text" value={tags} />
                            <select onChange={handleSelectChange}>
                                <option value="select">Select</option>
                                <option value="News">News</option>
                                <option value="How to">How to</option>
                                <option value="Integrations">Integrations</option>
                            </select>
                        </div>
                    </div>
                    <div className={styles.create__block}>
                        <span>Author</span> 
                        <div>
                            <input type="text" value={post.author} onChange={(e)=> setPost({...post,author:e.target.value})}/>
                            <input type="file" accept="image/*" onChange={handleImageChangeAuthor} />
                        </div>
                    </div>
                    <TinyMCEEditor setText={(text)=> setPost({...post,text})}/>
                    <Split style={{marginTop:"20px", marginBottom:"20px"}}/>
                    <div className={styles.create__buttons}>
                        <OutLineBtn text={"Back"} onClick={()=> navigate('/blog')}/>
                        <OutLineBtn text={"Demo"} onClick={()=> window.open(`https://gsocks.net/blog/demo/${post.link}`, '_blank')}/>
                        <BlueBtn text={"Save"} onClick={saveAction}/>
                    </div>
                </div>
            </div>
            <Snackbar open={stateCreate.success} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={()=>setStateCreate({success:false,error:false})}>
                <Alert onClose={()=>setStateCreate({success:false,error:false})} severity="success">
                    Post saved !
                </Alert>
            </Snackbar>
            <Snackbar open={stateCreate.error} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={()=>setStateCreate({success:false,error:false})}>
                <Alert onClose={()=>setStateCreate({success:false,error:false})} severity="error">
                    Post not saved !
                </Alert>
            </Snackbar>
        </>
    )
}

export default BlogCreate


