import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  auth: false,
  stateAuth: 'idle'
}

export const loginAction = createAsyncThunk('user/loginAction', async (code)=>{
    const resp = await axios.post('https://api.admin.gsocks.net/auth/signin', {code})
    const data = await resp.data;
    return data
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuth: (state,action) => {
        state.auth = action.payload.auth
        state.stateAuth = action.payload.stateAuth
    }
  },
  extraReducers: (build) => {
    build.addCase(loginAction.fulfilled, (state, action) => {
      state.auth = true
      state.stateAuth = 'success'
      localStorage.setItem('token', action.payload.token)
    })
    build.addCase(loginAction.rejected, (state, action) => {
      state.auth = false
      state.stateAuth = 'error'
    })
  }
})

export const {setAuth} = userSlice.actions
export default userSlice.reducer