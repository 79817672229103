import React, {useEffect, useState} from 'react'
import styles from './proxies.module.css'
import Split from '../../components/Split/Split'
import CreateIcon from '@mui/icons-material/Create';
import Modal from '../../components/Modal/Modal';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import BlueBtn from '../../components/BlueBtn/BlueBtn';
import OutLineBtn from '../../components/OutLineBtn/OutLineBtn';
import {Alert,Snackbar} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuth } from '../../store/slices/userSlice';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { loadProxy, searchProxy, setStateLimit, setStatePage, setStateUpdate, updateProxy } from '../../store/slices/proxySlice';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const Proxies = () => {

    const [order ,setOrder] = useState({
        order: 'id',
        order_type: 'ASC'
    })

    const proxyState = useSelector((store)=>store.proxySlice)
    

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [activeModalEdit, setActiveModalEdit] = useState(false);
    const [activeModalEditSave, setActiveModalEditSave] = useState(false); 
    const [activeModalEditSaveCancel, setActiveModalEditSaveCancel] = useState(false);

    const [alertEditSuccess, setAlertEditSuccess] = useState(false);
    const [alertCancelTextError, setAlertCancelTextError] = useState(false);

    const [stateSale, setStateSale] = useState("")

    const [seleteced, setSelected] = useState({
        status: "",
        note: "",
        id: -1,
        statuses: []
    })
    
    const [saveFirst,setSaveFirst] = useState(false)

    const [timerInput, setTimerInput] = useState(null);
    
    const [searchText, setSearchText] = useState('');
    const [page,setPage] = useState(1);
    const [limit,setLimit] = useState(10);

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(goSearch, 1500);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [searchText]); 

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changeLimit, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [limit]); 

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changePage, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [page]); 




    useEffect(()=>{
        if (proxyState.stateUpdate === 'success') {
            dispatch(loadProxy({page:proxyState.page,limit:proxyState.limit,order}))
            setAlertEditSuccess(true)
            dispatch(setStateUpdate('idle'))
        } 
    },[proxyState.stateUpdate])


    useEffect(()=>{
        if (proxyState.stateLoad == 'success') {
            setStateSale(seleteced.status)
        }
    },[seleteced])

    useEffect(()=>{
        goSearch()
    },[proxyState.page, proxyState.limit])


    useEffect(()=>{
        if (proxyState.stateLoad == 'error') {
            localStorage.removeItem('token');
            dispatch(setAuth({auth:false,stateAuth:'error'}))
            navigate('/login')
        }
    },[proxyState.stateLoad])

    useEffect(()=>{
        goSearch()
    },[order])

    const goSearch = () => {
        if(searchText != "") {
            dispatch(searchProxy({page:proxyState.page,limit:proxyState.limit,searchText,order}))
        } else {
            dispatch(loadProxy({page:proxyState.page,limit:proxyState.limit,order}))
        }
    };

    const changeLimit = () => {
        if (!limit) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else if (limit <= 0) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else {
            dispatch(setStateLimit(limit))
        }
    }

    const changePage = () => {
        if (!page) {
            setPage(1)
            dispatch(setStatePage(1))
        } else if (page > proxyState.pages) {
            setPage(proxyState.pages)
            dispatch(setStatePage(proxyState.pages))
        } else {
            dispatch(setStatePage(page))
        }
    }


    const save = () => {
        const defSale = proxyState.proxies.find(s => s.id === seleteced.id)
        if (seleteced.note != defSale.note) {
            dispatch(updateProxy({id:seleteced.id,note:seleteced.note,type:seleteced.type}))
        } else {
            setAlertEditSuccess(true)
        }
        setActiveModalEditSave(false)
    }


    const clickSort = (type) => {
        if (order.order == type) {
            setOrder({...order,order_type: order.order_type == 'ASC' ? 'DESC' : "ASC"})
        } else {
            setOrder({order:type, order_type:'ASC'})
        }
    }

    const openProxy = (id) => {
        window.open(`https://admin.gsocks.net/proxies/${id}`, '_blank');
    }

    const [alertCopy,setAlertCopy] = useState(false);

    const copyProxy = (id) => {
        navigator.clipboard.writeText(`https://admin.gsocks.net/proxies/${id}`);
        setAlertCopy(true);
    }

    if (proxyState.stateLoad == 'success') {
        return (
            <>
                <div className={styles.users}>
                    <h1>Proxies</h1>
                    <Split />
                    <div className={styles.tableSection}>
                        <div className={styles.tableControllers}>
                            <input
                                type="text"
                                value={searchText}
                                onChange={(e)=>setSearchText(e.target.value)}
                                placeholder="search"
                            />
                        </div>
                        <div className={styles.table}>
                            <div className={styles.table_row}>
                                <div>ID</div>
                                <div>Proxies type</div>
                                <div>Proxy</div>
                                <div>Traffic</div>
                                <div>Location</div>
                                <div>Rotation</div>
                                <div>Full name</div>
                                <div>Mail</div>
                                <div>Date</div>
                                <div>Status</div>
                                <div>Actions</div>
                            </div>
                            {
                                proxyState.proxies.map((proxy,idx)=> (
                                    <div className={styles.table_row} key={idx}>
                                        {console.log(proxy)}
                                        <div>{proxy.id}</div>
                                        <div>{proxy.type}</div>
                                        {
                                            proxy.type == 'residential' ?
                                            <div className={styles.table_row_proxy}><span onClick={()=>openProxy(proxy.id)} style={{cursor:'pointer'}}>Proxy list</span><FileCopyIcon onClick={()=>copyProxy(proxy.id)} style={{cursor:'pointer'}}/></div>
                                            :
                                            <div>
                                                <div>IP: {proxy.ip}</div>
                                                <div>Log: {proxy.username}</div>
                                                <div>Pass: {proxy.password}</div>
                                                <div>HTTP: {proxy.port_http}</div>
                                                <div>SOCKS5: {proxy.port_socks5}</div>
                                            </div>
                                        }
                                        <div>{proxy.type == "private" ? `${proxy.volume} days` : `${proxy.rest_volume}/${proxy.volume *1024}`}</div>
                                        <div>{proxy.country}</div>
                                        <div>{proxy.next_rotation}</div>
                                        <div>{proxy.full_name}</div>
                                        <div>{proxy.email}</div>
                                        <div>{proxy.create}</div>
                                        <div>{proxy.status}</div>
                                        <div onClick={()=> {setActiveModalEdit(true);setSelected({
                                            ...proxy,
                                        })}}><CreateIcon /></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={styles.table_pagination}>
                            <div className={styles.table_pagination__rows}>
                                <span>Rows per page</span>
                                <input type="number" value={limit} onChange={(e)=>setLimit(e.target.value)} />
                            </div>
                            <div className={styles.table_pagination__pages}>
                                <KeyboardArrowLeftIcon onClick={()=>setPage((p)=>p == 1 ? 1 : p-1)}/>
                                <input type="number" value={page} onChange={(e)=>setPage(e.target.value)} />
                                <span>of</span>
                                <span>{proxyState.pages}</span>
                                <KeyboardArrowRightIcon onClick={()=>setPage((p)=>p == proxyState.pages ? p : p+1)}/>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal active={activeModalEdit} off={() => setActiveModalEdit(false)}>
                    <div className={styles.modalEdit} onClick={(e)=>e.stopPropagation()}>
                        <h2>Action</h2>
                        <Split style={{marginTop:"20px", marginBottom:"20px"}}/>
                        <div className={styles.modalEdit__header}>
                            <div>
                                <span>ID: {seleteced.id}</span>
                                <span>Volume: {seleteced.type == "private" ? `${seleteced.volume} days` : `${seleteced.volume *1024}/${seleteced.rest_volume}`}</span>
                            </div>
                            <div>
                                <span>Proxies type: {seleteced.type}</span>
                                <span>Country: {seleteced.country}</span>
                            </div>
                            <div style={{marginTop:'20px'}}>
                                <span>Client: {seleteced.email}</span>
                            </div>
                        </div>
                        <div>
                            <span>Note</span>
                            <textarea onChange={e=>setSelected({...seleteced,note:e.target.value})} value={seleteced.note ? seleteced.note : ""}></textarea>
                        </div>
                        <Split style={{marginTop:"20px", marginBottom:"20px"}}/>
                        <div className={styles.modalEdit__btns}>
                            <OutLineBtn text={"Cancel"} onClick={()=>setActiveModalEdit(false)}/>
                            <BlueBtn text={"Save"} onClick={()=> {setActiveModalEdit(false);setActiveModalEditSave(true)}}/>
                        </div>
                    </div>
                </Modal>
                <Modal active={activeModalEditSave} off={() => setActiveModalEditSave(false)}>
                    <div className={styles.modalEdit} onClick={(e)=>e.stopPropagation()}>
                        <h2 style={{marginBottom:"30px", marginTop:"20px"}}>Are you sure to save the changes ?</h2>
                        <div className={styles.modalEdit__btns}>
                            <OutLineBtn text={"Cancel"} onClick={()=>setActiveModalEditSave(false)}/>
                            <BlueBtn text={"Save"} onClick={save}/>
                        </div>
                    </div>
                </Modal>
                <Snackbar open={alertEditSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={()=>setAlertEditSuccess(false)}>
                  <Alert onClose={()=>setAlertEditSuccess(false)} severity="success">
                  Data updated successfully !
                  </Alert>
                </Snackbar>
                <Snackbar open={alertCopy} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={()=>setAlertCopy(false)}>
                <Alert onClose={()=>setAlertCopy(false)} severity="success">
                Successfully copied!
                </Alert>
                </Snackbar>
            </>
        )
    } else return ''
}

export default Proxies