import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    users:[],
    count: 0,
    page: 1,
    limit: 10,
    pages: 1,
    stateLoad: 'idle',
    stateUpdate: 'idle',
}

export const loadUsers = createAsyncThunk('users/loadUsers', async ({page,limit,order})=>{
    const resp = await axios.get('https://api.admin.gsocks.net/client',{
        headers: {
          'authorization': `session ${localStorage.getItem('token')}`
        },
        params: {
          page,limit,
          order: order.order,
          order_type: order.order_type
        }
    })
    return resp.data
})

export const banUnbanUser = createAsyncThunk('users/banUnbanUser', async (data)=>{
    const resp = await axios.post(`https://api.admin.gsocks.net/client/ban/${data.id}`,{note:data.note ? data.note : ""},{
        headers: {
          'authorization': `session ${localStorage.getItem('token')}`
        }
    })
    return resp.data
})

export const updateUser = createAsyncThunk('users/updateUser', async (data)=>{
  const resp = await axios.post(`https://api.admin.gsocks.net/client/${data.id}`,{note:data.note ? data.note : "", email: data.email, full_name: data.full_name},{
    headers: {
      'authorization': `session ${localStorage.getItem('token')}`
    }
  })
  return resp.data
})

export const changeBalanceUser = createAsyncThunk('users/updateUser', async (data)=>{
  const resp = await axios.post(`https://api.admin.gsocks.net/client/balance/${data.id}`,{note:data.note ? data.note : "", sum: data.balance},{
    headers: {
      'authorization': `session ${localStorage.getItem('token')}`
    }
  })
  return resp.data
})

export const searchUsers = createAsyncThunk('users/searchSales', async ({page,limit,searchText,order})=>{
  const resp = await axios.get('https://api.admin.gsocks.net/client',{
      headers: {
        'authorization': `session ${localStorage.getItem('token')}`
      },
      params: {
        page,limit,
        'filter': searchText,
        order: order.order,
        order_type: order.order_type
      }
  })
  return resp.data
})

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setStateUpdate: (state,action) => {
        state.stateUpdate = action.payload
    },
    setStateLimit: (state,action) => {
      state.limit = Number(action.payload)
    },
    setStatePage: (state,action) => {
      state.page = Number(action.payload)
    }
  },
  extraReducers: (build) => {
    build.addCase(loadUsers.fulfilled, (state, action) => {
      state.count = action.payload.count
      state.users = action.payload.clients
      state.pages = Math.ceil(state.count / state.limit)
      state.stateLoad = 'success'
    })
    build.addCase(loadUsers.rejected, (state, action) => {
      state.stateLoad = 'error'
    })
    build.addCase(searchUsers.fulfilled, (state, action) => {
      state.count = action.payload.count
      state.users = action.payload.clients
      state.stateLoad = 'success'
    })
    build.addCase(banUnbanUser.fulfilled, (state, action) => {
      state.stateUpdate = 'success'
    })
    build.addCase(updateUser.fulfilled, (state, action) => {
      state.stateUpdate = 'success'
    })
  }
})


export const {setStateUpdate, setStateLimit, setStatePage} = usersSlice.actions
export default usersSlice.reducer