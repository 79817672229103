// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `








.BarLeft_barleft__VjBik {
    width: 200px;
    height: calc(100vh + 80px);
    position: fixed;

    display: flex;
    flex-direction: column;

    text-align: center;
    padding: 50px 20px;

    border-right: 2px solid #d9d9ff;

    transform: translateY(-80px);
}

.BarLeft_links__7ktWW > a {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.BarLeft_barleft__VjBik img {
    margin-top: 10px;
    width: 160px;
    margin-bottom: 80px;
}

.BarLeft_links__7ktWW > a:hover {
    cursor: pointer;
    opacity: 0.6;
}

.BarLeft_links__7ktWW {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/components/BarLeft/BarLeft.module.css"],"names":[],"mappings":";;;;;;;;;AASA;IACI,YAAY;IACZ,0BAA0B;IAC1B,eAAe;;IAEf,aAAa;IACb,sBAAsB;;IAEtB,kBAAkB;IAClB,kBAAkB;;IAElB,+BAA+B;;IAE/B,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B","sourcesContent":["\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n.barleft {\r\n    width: 200px;\r\n    height: calc(100vh + 80px);\r\n    position: fixed;\r\n\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n    text-align: center;\r\n    padding: 50px 20px;\r\n\r\n    border-right: 2px solid #d9d9ff;\r\n\r\n    transform: translateY(-80px);\r\n}\r\n\r\n.links > a {\r\n    font-size: 20px;\r\n    margin-bottom: 10px;\r\n    font-weight: bold;\r\n}\r\n\r\n.barleft img {\r\n    margin-top: 10px;\r\n    width: 160px;\r\n    margin-bottom: 80px;\r\n}\r\n\r\n.links > a:hover {\r\n    cursor: pointer;\r\n    opacity: 0.6;\r\n}\r\n\r\n.links {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: flex-start;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"barleft": `BarLeft_barleft__VjBik`,
	"links": `BarLeft_links__7ktWW`
};
export default ___CSS_LOADER_EXPORT___;
