
import React from 'react'
import styles from './blueBtn.module.css'

const BlueBtn = ({text, className='', onClick, style}) => {
    return (
      <button className={`${styles.button_blue} ${className}`} style={style} onClick={onClick} >{text}</button>
    ) 
  }

export default BlueBtn