import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './charStyle.css';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const totalReg = payload[0].payload.totalReg;
    return (
      <div className="custom-tooltip">
        <p>{`Date: ${payload[0].payload.date}`}</p>
        <p>{`Verified Registrations: ${payload[1].value}`}</p>
        <p>{`Unverified Registrations: ${payload[0].value}`}</p>
        <p>{`Total Registrations: ${totalReg}`}</p>
      </div>
    );
  }

  return null;
};

const BarChartComponent = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        barSize={70}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar dataKey="unverifiedReg" stackId="a" fill="#ffc658" />
        <Bar dataKey="verifiedReg" stackId="a" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
